import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Usage`}</h2>
    <p>{`Elevation is used to indicate hierarchy and create focus through the use of depth. Components with the same elevations create an effect of grouping and similarity between content while components on different elevations reflect spatial relationships.
Elevation is depicted through shadows to create an effect of depth. Components closer to the base layer have sharper shadows, while components that are elevated have softer shadows. You can chek out the
guideline for more detail description.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`import LegionUI
`}</code></pre>
    <div className="divi" />
    <h2>{`Preview`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Shape`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Func Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Box Shadow`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "119px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "97.47899159663864%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAWUlEQVQ4y+2UQQrAMAgE8/8Xx+hqY0ig0FNjCzkoLIjgsKcpNkdVQ1mM4osIjBqHggkdQGaxSi0UAE/gbrsEJjCB5wHfCIFuPz83lI/15ab1w26Gsd3cHXgBdDXX5NmG2CkAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "shape preview",
                "title": "shape preview",
                "src": "/static/35b613c4945104b2970710329707bb54/10634/LGNElevation1.png",
                "srcSet": ["/static/35b613c4945104b2970710329707bb54/10634/LGNElevation1.png 119w"],
                "sizes": "(max-width: 119px) 100vw, 119px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`elevation1()`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0px 1px 0px rgba(0, 0, 0, 0.05)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "117px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "98.2905982905983%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAeElEQVQ4y+2VywqAQAhF5/+/t/KResOBJqJNNS1ajHAQBA+urgUA3L2brIhAEVUsxCBmEL2Aue6bWZWWHLKukA6IBXnYIRSFiNb+lF2oF6Gurd9lCIdwCH8o/C4cCCw98WXn+MrLpnnB3EkL2PYCzOD+nBRFHC9gA+fQIhh6/50bAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "shape preview",
                "title": "shape preview",
                "src": "/static/f66eaa2f381054efa2544ac0222193a3/38c91/LGNElevation2.png",
                "srcSet": ["/static/f66eaa2f381054efa2544ac0222193a3/38c91/LGNElevation2.png 117w"],
                "sizes": "(max-width: 117px) 100vw, 117px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`elevation2()`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0px 1px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "133px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA1UlEQVQ4y+2VwQ6DIBBE+f+fFVgQFtBtZluM9dCiTXrSZDiIPGY3MhgRkXVd37Qsy5CO6/CYDqi1Ss5ZUkoyz/NXJSglYc7SWtugCiylSAhBKATxRMMieq6JMW5QA3d4QSFKqU241NNy3gsza6UKDLpjUGDmckoAWuu0XQqEVXqVcBU4Wav9/Aj8VuYNvIH/AF7+sTHQz0fvAOxJczkcnBPuZxkDksJ70gnYn6YxwZlzXg0hT7c8RB8BRXBiclSIPZSKPO0JbvaxDzCE3UaEb/fXAa6ABwXRG6Q1kn49AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "shape preview",
                "title": "shape preview",
                "src": "/static/c9288e69c34b3c45dc153b8981482e86/aa6b0/LGNElevation3.png",
                "srcSet": ["/static/c9288e69c34b3c45dc153b8981482e86/aa6b0/LGNElevation3.png 133w"],
                "sizes": "(max-width: 133px) 100vw, 133px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`elevation3()`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "150px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "92%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAABYElEQVQ4y72U6Y7CMAyEef9Xbe62ucGrGZqKdg8WVtofI1CcfrEdZy7X61V679Jak1qr5JwlpSQxxm+FOPZhP77D9+Dcbje5DBg2LMsiIQRxzom1ljLG7BpriHsfZJ5nwlutO5TAUgqDMSZJuUhM+anS9jvPC5NBUgQi7RSjeO8JK7W9JGS6rivLR3IXZIcFlDKAudRfyzrH6sAhcPROa30A/pTViOfaxBjLvo+yCcQJSqnXgaWJ1ubeLlwOgPiDE94C1iZKa976J+A0TS8DIaXuwPgPQPUe8Fzy3y9FHy9lAM9j80wDiCcZwgMQA4k5ROCtwbaWCSExDjaeDByEfchFautbaV1K+0K1H8p33jOh/aUMp8Ep6IW1jsOK25uU4mXtUorriBu4jvcSwnCcu43RbWgQKcmyQy17qgg4Cutoj9veMKob2dFthsE+musaIw0DpZyFdQigYbJ9sy4APwCpcHgNOjMrMAAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "shape preview",
                "title": "shape preview",
                "src": "/static/11b5d5140c4e0288e6a12cbc88d5df3d/7d2fc/LGNElevation4.png",
                "srcSet": ["/static/11b5d5140c4e0288e6a12cbc88d5df3d/7d2fc/LGNElevation4.png 150w"],
                "sizes": "(max-width: 150px) 100vw, 150px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`elevation4()`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0px 4px 20px rgba(0, 0, 0, 0.15), 0px 0px 3px rgba(0, 0, 0, 0.1)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "179px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100.5586592178771%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABk0lEQVQ4y82U25aDIAxF+f/v7E0UteIF1K7MOmkPC5npXN7m4ZQCyTaJMebxeMi+77Jtm2pdVwkhqJZlUc3znP7jPMaodvSBPziQIYwgAqZpUo3jmMQzPiCGkMCEmhwGQzgOwyD3+136vpeu65Kwx7kfBrWDfSigJod57w+gtm3FQe65tm0B9v4AVSDqwchgGOIqcd1+1BKi2jNScBClQS1QF6SJiAjUtVB4iUDnnEYJf3AQpcmja5rmAHynJzCoPVLPozSg4ymojbX1McIvUuX5vASx1qpfXkuT0nVOKmv/BKyqSssEf3AOQNQDBn8B3m63fw58mzKKigtb1PC7t1y+lATkF9J3ndRNo8brtktcd11L8R59WNf1p+bWxsYB+glpj9P80iR+PGqEdEDMuoc9002NnQ8FNjdqc71e5XK5yPl8ltPppCv2OMd92dTg6KcHKj8/DgfUBU+HE9KisEe/4n54TZw8OgXiB7kT+pvxVcI4GHTacMASyuEKB0RciiCmmcPSxH43tSkCKEaVD1YCPwAB1A0XNGJ2NgAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "shape preview",
                "title": "shape preview",
                "src": "/static/76185e8182d1374bd0997059d644ba18/4f66b/LGNElevation5.png",
                "srcSet": ["/static/76185e8182d1374bd0997059d644ba18/4f66b/LGNElevation5.png 179w"],
                "sizes": "(max-width: 179px) 100vw, 179px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`elevation5()`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0px 8px 40px rgba(0, 0, 0, 0.2), 0px 0px 4px rgba(0, 0, 0, 0.1)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`content.elevation1()
content.elevation2()
content.elevation3()
content.elevation4()
content.elevation5()
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      